import React, { useState } from 'react';
import { connect } from "react-redux";
import { updateTableState, updateJokerList, updateTripleStarIndex, updateAvailableChars, updateKey } from "./redux/actions";
import AreYouSurePage from './AreYouSurePage';

const LoadableTable = (props) => {

    const loadTable = () => {
        props.updateAvailableChars(props.tableInfo.table.chars);
        props.updateJokerList(props.tableInfo.table.jokerList);
        props.updateTableState(props.tableInfo.table.tableState);
        props.updateTripleStarIndex(props.tableInfo.table.tripleStarIndex);
        props.updateKey(new Date().getTime());
        props.closeDropDownCallback();
    }

    return (
        <DropDownItemAreYouSure text={props.tableInfo.tableName} loadTable={loadTable} />
    );
};

const DropDownItemAreYouSure = (props) => {
    const [clicked, setClicked] = useState(false);

    const onClick = (e) => {
        setClicked(true);
    }

    const areYouSureReturned = (result) => {
        if (result) {
            props.loadTable();
        }
        setClicked(false);
    }

    return <div className="containerWidth">
        {clicked ? <AreYouSurePage callback={areYouSureReturned} title={`${props.text} isimli tahta yüklenecek. Emin misiniz?`} /> : null}
        <div className="DropDownItem" onClick={onClick}>{props.text}</div>
    </div>
}

export default connect(
    null,
    { updateTableState, updateJokerList, updateTripleStarIndex, updateAvailableChars, updateKey }
)(LoadableTable);