import React from 'react';
import { connect } from "react-redux";
import { getUserAndTableInfo } from './redux/selectors';
import { resetToken, resetUser } from "./redux/actions";
import { getTableState } from './redux/selectors';
import { updateTableState, updateJokerList, updateTripleStarIndex, resetTableState, updateAvailableChars, updateKey, clearActiveTableName } from "./redux/actions";
import { timeoutFetch } from './utilities';
import LoadingPage from "./LoadingPage";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import API_URL from './backend';
import UserTables from './UserTables';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const DEFAULT_DROPDOWN = "DEFAULT_DROPDOWN";
const SAVE_TABLE = "SAVE_TABLE";
const LOAD_TABLE = "LOAD_TABLE";
const SHOW_TABLES = "SHOW_TABLES";

class DropDownMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            select: DEFAULT_DROPDOWN,
            tableName: "",
            loading: false
        }

        this.logout = this.logout.bind(this);
        this.textChanged = this.textChanged.bind(this);
        this.saveTable = this.saveTable.bind(this);
        this.loadTable = this.loadTable.bind(this);
    }

    logout() {
        this.props.resetUser();
        this.props.resetToken();
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.props.clearActiveTableName();
    }

    textChanged(event) {
        this.setState({ tableName: event.target.value });
    }

    isTablenameEligible = () => {
        const name = this.state.tableName;
        return name && typeof name === 'string' && name.length > 0;
    }

    async saveTable() {
        if (!this.isTablenameEligible()) {
            NotificationManager.warning('', `Tahta ismi uygun değil`, 2000);
            return;
        }
        this.setState({ loading: true });
        let success = false;
        try {
            const response = await timeoutFetch(10000, fetch(`${API_URL}/table/save`, {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${this.props.token}`
                },
                body: JSON.stringify({ tableName: this.state.tableName, table: this.props.table })
            }));

            const responseBody = await response.json();
            console.log(responseBody);
            if (responseBody.status == 'success') {
                NotificationManager.success(`Tahta (${responseBody.data.tableName}) kaydedildi`, '', 1000);
                success = true;
            } else {
                NotificationManager.warning(responseBody.reason, `Tahta kaydedilemedi`, 2000);
            }
        } catch (err) {
            NotificationManager.warning(err.message, `Tahta kaydedilemedi`, 2000);
        }
        this.setState({
            loading: false,
            select: success ? DEFAULT_DROPDOWN : this.state.select
        });
        this.props.closeDropDownCallback();
    }

    async loadTable() {
        if (!this.isTablenameEligible()) {
            NotificationManager.warning('', `Tahta ismi uygun değil`, 2000);
            return;
        }
        this.setState({ loading: true });
        let success = false;
        try {
            const response = await timeoutFetch(10000, fetch(`${API_URL}/table/load`, {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${this.props.token}`
                },
                body: JSON.stringify({ tableName: this.state.tableName })
            }));

            const responseBody = await response.json();
            console.log(responseBody);
            if (responseBody.status == 'success') {
                success = true;
                NotificationManager.success(`Tahta (${responseBody.data.tableName}) getirildi`, '', 1000);
                const table = responseBody.data.table;
                this.props.updateAvailableChars(table.chars);
                this.props.updateJokerList(table.jokerList);
                this.props.updateTableState(table.tableState);
                this.props.updateTripleStarIndex(table.tripleStarIndex);
                this.props.updateKey(new Date().getTime());
            } else {
                NotificationManager.warning(responseBody.reason, `Tahta yüklenemedi`, 2000);
            }
        } catch (err) {
            NotificationManager.warning(err.message, `Tahta yüklenemedi`, 2000);
        }
        this.setState({
            loading: false,
            select: success ? DEFAULT_DROPDOWN : this.state.select
        });
        this.props.closeDropDownCallback();
    }

    onClick = (e) => {
        e.stopPropagation();
    }

    getResultingComponent = () => {
        switch (this.state.select) {
            case SAVE_TABLE:
                return (
                    <div className="DropDownMenuContainer" onClick={this.onClick}>
                        <DropDownTextBox onChange={this.textChanged} />
                        <DropDownItem text="Kaydet" callback={this.saveTable} />
                    </div>
                );
            case LOAD_TABLE:
                return (
                    <div className="DropDownMenuContainer" onClick={this.onClick}>
                        <DropDownTextBox onChange={this.textChanged} />
                        <DropDownItem text="Yükle" callback={this.loadTable} />
                    </div>
                );
            case SHOW_TABLES:
                return (
                    <div className="DropDownMenuContainer" onClick={this.onClick}>
                        <UserTables closeDropDownCallback={this.props.closeDropDownCallback} />
                    </div>
                );
            case DEFAULT_DROPDOWN:
            default:
                return (
                    <div className="DropDownMenuContainer" onClick={this.onClick}>
                        <DropDownItem text="Tahtalarım" callback={() => this.setState({ select: SHOW_TABLES, tableName: "" })} />
                        <DropDownItem text="Tahta Kaydet" callback={() => this.setState({ select: SAVE_TABLE, tableName: "" })} />
                        <DropDownItem text="Tahta Yükle" callback={() => this.setState({ select: LOAD_TABLE, tableName: "" })} />
                        <PrivacyDropDownItem closeDropDownCallback={this.props.closeDropDownCallback} />
                        <DropDownItem text="Çıkış" callback={this.logout} />
                    </div>
                );
        }
    }

    render() {
        if (this.state.loading) {
            return (<LoadingPage fullPage={true} />);
        }
        return (
            <div className="fullPage" onClick={this.props.closeDropDownCallback}>
                {this.getResultingComponent()}
            </div>
        );
    }
}

const PrivacyDropDownItem = (props) => {
    const history = useHistory();

    function goToPrivacyPage() {
      history.push("/privacy");
      if(props.closeDropDownCallback){
          props.closeDropDownCallback();
      }
    }

    return (
      <DropDownItem text='Gizlilik' callback={goToPrivacyPage} />
    );
}

const DropDownItem = (props) => {
    const onClick = (e) => {
        if (props.callback) {
            props.callback();
        } else {
            console.log('Callback not supplied');
        }
    }
    return (<div className="DropDownItem" onClick={onClick}>{props.text}</div>);
}

const DropDownTextBox = (props) => {
    return (<input className="DropDownTextBox" placeholder="Tahta İsmi" onChange={props.onChange} />);
};

export default connect(
    getUserAndTableInfo,
    { resetUser, resetToken, updateTableState, updateJokerList, updateTripleStarIndex, updateAvailableChars, updateKey, clearActiveTableName }
)(DropDownMenu);