export const getTableState = (store) => {
    const { tableState, jokerList, tripleStarIndex } = store.tableStateReducer || {};
    const { chars } = store.availableCharReducer || {};
    return { tableState, jokerList, tripleStarIndex, chars };
}

export const getToken = (store) => {
    return {
        token: store.tokenReducer.token
    }
};

export const getUser = (store) => {
    return {
        user: store.userReducer.user
    }
}

export const getLoggedInInfo = (store) => {
    return {
        token: store.tokenReducer.token,
        user: store.userReducer.user
    }
}

export const getUserAndTableInfo = (store) => {
    const { tableState, jokerList, tripleStarIndex } = store.tableStateReducer || {};
    const { chars } = store.availableCharReducer || {};
    return {
        token: store.tokenReducer.token,
        user: store.userReducer.user,
        table: {
            tableState,
            jokerList,
            tripleStarIndex,
            chars
        }
    }
}

export const getKey = (store) => {
    return {
        key: store.keyReducer.key
    }
}

export const getTables = (store) => {
    return {
        tables: store.tablesReducer.tables
    }
}

export const getActiveTableName = (store) => {
    return {
        activeTableName: store.activeTableNameReducer.activeTableName
    }
}

export const getUnsavedTable = (store) => {
    return {
        unsavedTableExists: store.unsavedTableReducer.unsavedTableExists,
        unsavedTable: store.unsavedTableReducer.unsavedTable
    }
}