import React from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { checkUsernameValidity, checkPasswordValidity, timeoutFetch } from './utilities';
import LoadingPage from "./LoadingPage";
import API_URL from './backend';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isLoading: false
        }
        this.tryRegister = this.tryRegister.bind(this);
        this.usernameUpdated = this.usernameUpdated.bind(this);
        this.passwordUpdated = this.passwordUpdated.bind(this);
        this.keyDownCallback = this.keyDownCallback.bind(this);
    }

    async tryRegister() {
        if (!checkUsernameValidity(this.state.username)) {
            NotificationManager.warning('Kullanıcı adı uygun değil', 'Kullanıcı eklenemedi', 2000);
            return;
        }

        if (!checkPasswordValidity(this.state.password)) {
            NotificationManager.warning('Şifre uygun değil', 'Kullanıcı eklenemedi', 2000);
            return;
        }
        this.setState({ loading: true });

        const user = {
            username: this.state.username,
            password: this.state.password
        };
        try {
            const response = await timeoutFetch(10000, fetch(`${API_URL}/user/add`, {
                method: 'POST',
                //mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user })
            }));

            const responseBody = await response.json();

            console.log(responseBody)

            if (responseBody.status == 'success') {
                NotificationManager.success('', 'Başarılı', 2000);
                this.props.history.push(`/login`);
            } else {
                NotificationManager.warning(responseBody.reason, 'Kullanıcı eklenemedi', 2000);
            }

        } catch (err) {
            console.log(err);
            NotificationManager.warning('Sunucu ile bağlantı sırasında hata oluştu', '', 2000);
        }
        
        this.setState({ loading: false });
    }

    usernameUpdated(event) {
        this.setState({
            username: event.target.value
        });
    }

    passwordUpdated(event) {
        this.setState({
            password: event.target.value
        });
    }

    keyDownCallback(event) {
        if (event.key == 'Enter') {
            this.tryRegister();
        }
    }

    render() {
        let result =
            <div className="RegisterBox">
                <div className="RegisterLabel">Kayıt Ol</div>
                <input type="text" class="input-group-text" placeholder="Kullanıcı adı" value={this.state.username} onChange={this.usernameUpdated} />
                <input type="password" class="input-group-text" placeholder="Şifre" value={this.state.password} onChange={this.passwordUpdated} />
                <button type="button" className="btn btn-primary" onClick={this.tryRegister}>Kayıt Ol</button>
            </div>;
        if (this.state.loading) {
            result = <LoadingPage />
        }
        return (
            <div className="RegisterPageContainer" onKeyDown={this.keyDownCallback}>
                {result}
            </div>
        );
    }
};

export default RegisterPage;