import {
    UPDATE_TABLE_STATE,
    UPDATE_JOKER_LIST,
    UPDATE_TRIPLE_STAR_INDEX,
    RESET_TABLE_STATE,
    UPDATE_AVAILABLE_CHARS,
    UPDATE_TOKEN,
    RESET_TOKEN,
    UPDATE_USER,
    RESET_USER,
    UPDATE_KEY,
    UPDATE_TABLES,
    CLEAR_TABLES,
    SET_ACTIVE_TABLE_NAME,
    CLEAR_ACTIVE_TABLE_NAME,
    OPEN_UNSAVED_TABLE,
    UPDATE_UNSAVED_TABLE,
    CLEAR_UNSAVED_TABLE,
    UPDATE_SINGLE_TABLE,
    PRIORITIZE_SINGLE_TABLE,
    REMOVE_TABLE
} from "./actionTypes";

export const updateTableState = (tableState) => ({
    type: UPDATE_TABLE_STATE,
    payload: {
        tableState
    }
});

export const updateJokerList = (jokerList) => ({
    type: UPDATE_JOKER_LIST,
    payload: {
        jokerList
    }
});

export const updateTripleStarIndex = (tripleStarIndex) => ({
    type: UPDATE_TRIPLE_STAR_INDEX,
    payload: {
        tripleStarIndex
    }
});

export const resetTableState = () => ({
    type: RESET_TABLE_STATE,
    payload: {}
});

export const updateAvailableChars = chars => ({
    type: UPDATE_AVAILABLE_CHARS,
    payload: { chars }
});

export const updateToken = token => ({
    type: UPDATE_TOKEN,
    payload: { token }
});

export const updateUser = user => ({
    type: UPDATE_USER,
    payload: { user }
});

export const resetToken = () => ({
    type: RESET_TOKEN,
    payload: {}
});

export const resetUser = () => ({
    type: RESET_USER,
    payload: {}
});

export const updateKey = (key) => ({
    type: UPDATE_KEY,
    payload: { key }
});

export const updateTables = (tables) => ({
    type: UPDATE_TABLES,
    payload: { tables }
});

export const clearTables = () => ({
    type: CLEAR_TABLES,
    payload: {}
});

export const setActiveTableName = (activeTableName) => ({
    type: SET_ACTIVE_TABLE_NAME,
    payload: { activeTableName }
});

export const clearActiveTableName = () => ({
    type: CLEAR_ACTIVE_TABLE_NAME,
    payload: {}
});

export const openUnsavedTable = () => ({
    type: OPEN_UNSAVED_TABLE,
    payload: {}
});

export const updateUnsavedTable = (table) => ({
    type: UPDATE_UNSAVED_TABLE,
    payload: { table }
});

export const clearUnsavedTable = () => ({
    type: CLEAR_UNSAVED_TABLE,
    payload: {}
});

export const updateSingleTable = (table) => ({
    type: UPDATE_SINGLE_TABLE,
    payload: { table }
});

export const prioritizeSingleTable = (tableName) => ({
    type: PRIORITIZE_SINGLE_TABLE,
    payload: { tableName }
});

export const removeTable = (tableName) => ({
    type: REMOVE_TABLE,
    payload: { tableName }
});