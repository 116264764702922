import React from 'react';
import { connect } from "react-redux";
import { getLoggedInInfo } from './redux/selectors';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import options from './images/options.svg'; 
import UserActionsSection from './UserActionsSection';

class ActionsSection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.token == "" || this.props.token == undefined) {
            return (
                <NotLoggedInUserActions />
            );
        } else {
            return (
                <LoggedInUserActions user={this.props.user}/>
            );
        }
    }
}

const NotLoggedInUserActions = (props) => (
    <div style={{
        display: "flex",
        flexDirection: "row"
    }}>
        <NavLink to={'/privacy'} className="linkStyle leftRightPadding" activeClassName="activeLinkStyle" title="Gizlilik">
            Gizlilik
        </NavLink>
        <NavLink to={'/signup'} className="linkStyle leftRightPadding" activeClassName="activeLinkStyle" title="Kayıt Ol">
            Kayıt Ol
        </NavLink>
        <NavLink to={'/login'} className="linkStyle leftRightPadding" activeClassName="activeLinkStyle" title="Giriş">
            Giriş
        </NavLink>
    </div>
);

const LoggedInUserActions = (props) => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <span className="headerTextStyle leftRightPadding" >Hoşgeldin {props.user.username}</span>
            <UserActionsSection/>
        </div>
    );
};

export default connect(
    getLoggedInInfo,
    null
)(ActionsSection);