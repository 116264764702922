import { timeoutFetch } from './utilities';
import API_URL from './backend';

export const getTablesFromDB = async (token) => {
    try {
        const response = await timeoutFetch(10000, fetch(`${API_URL}/table/get`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            }
        }));

        const responseBody = await response.json();
        return responseBody.data.tables;
    } catch (err) {
        console.log(err);
        throw err;
        return [];
    }
};

export const getMoreTablesFromDB = async (token) => {
    try {
        const response = await timeoutFetch(10000, fetch(`${API_URL}/table/getMore`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            }
        }));

        const responseBody = await response.json();
        return responseBody.data.tables;
    } catch (err) {
        console.log(err);
        throw err;
        return [];
    }
};

export const saveTableToDB = async (name, table, token) => {
    try {
        const response = await timeoutFetch(10000, fetch(`${API_URL}/table/save`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ tableName: name, table })
        }));

        const responseBody = await response.json();
        console.log(responseBody);
        if (responseBody.status == 'success') {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw new Error(`Tahta kaydedilemedi`);
    }
}

export const deleteTableFromDB = async (tableName, token) => {
    try {
        const response = await timeoutFetch(10000, fetch(`${API_URL}/table/delete`, {
            method: 'POST',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ tableName })
        }));

        const responseBody = await response.json();
        console.log(responseBody);
        if (responseBody.status == 'success') {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw new Error(`Tahta kaydedilemedi`);
    }
}