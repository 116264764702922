import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import MainPage from './MainPage';
import RegisterPage from './RegisterPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import LoginPage from './LoginPage';
import TableTabs from './TableTabs';
import ActionsSection from './ActionsSection';
import { updateToken, resetToken, updateUser, resetUser, updateTables, clearTables } from "./redux/actions";
import { getKey, getToken } from './redux/selectors';

import { getMoreTablesFromDB } from './commonDBOperations';
import NotificationManager from 'react-notifications/lib/NotificationManager';

function App(props) {

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    const localUser = localStorage.getItem('user');
    if (localToken) {
      props.updateToken(localToken);
    }
    if (localUser) {
      props.updateUser(JSON.parse(localUser));
    }
  }, []);

  useEffect(() => {
    if (props.token) {
      loadTables(props.token);
    } else {
      props.clearTables();
    }
  }, [props.token]);

  const loadTables = async (token) => {
    try {
      props.updateTables(await getMoreTablesFromDB(token));
    } catch {
      NotificationManager.error('Tekrar giriş yapmanız gerekiyor', 'Tahtalar getirilemedi', 2000);
      props.resetToken();
      props.resetUser();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      props.clearTables();
    }
  };

  return (
    <Router>
      <Root>
        <Route path='/' component={Header} />
        <Route exact path='/'>
          <TableTabs />
          <MainPageWrapperComponent />
        </Route>
        <Route path='/privacy' component={PrivacyPolicyPage} />
        <Route path='/signup' component={RegisterPage} />
        <Route path='/login' component={LoginPage} />
        <NotificationContainer />
      </Root>
    </Router>
  );
}

const Announcement = () => {
  const [active, setActive] = useState(true);
  return active && (
    <div
      style={{
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#302857',
        paddingTop: 5,
        position: 'relative',
      }}
    >
    <div style={{
      height: 20,
    }}>
      <button
      style={{
        background: 'none',
        border: 'none',
        position: 'absolute',
        right: 10,
      }}
      onClick={() => setActive(false)}>
        &times;
      </button>
    </div>
    <div>
      iOS uygulamamız yayında <span role="img" aria-label="celebrate">🎉🥳</span>
    </div>
    <a href="https://apps.apple.com/tr/app/kelime-yardım-kelimelik-hile/id6444841611?l=tr">İndirmek için tıklayın</a>
      <div>
        Android uygulamamız yayında <span role="img" aria-label="celebrate">🎉🥳</span>
      </div>
      <a href="https://play.google.com/store/apps/details?id=com.kelimeyardimapp">İndirmek için tıklayın</a>
    </div>
  )
}

const MainPageWrapper = (props) => (
  <MainPage key={props.key} />
);

// This is to reset MainPage when reduxStore.key changes and not re-rendering NotificationContainer.
const MainPageWrapperComponent = connect(getKey, null)(MainPageWrapper);

const Root = (props) => (
  <div
    {...props}
  />
);

const Header = () => (<div className="header">
  <NameHolder />
  <ActionsSection />
</div >);

const NameHolder = () => (
  <NavLink exact to={'/'} className="linkStyle" activeClassName="activeLinkStyle">
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <img src="KelimeYardimLogo_192.png" height="50px" alt="Kelimelik Yardımcısı Logo" />Kelimelik Yardımcısı
      </div>
  </NavLink>
);

const EndOfLifeAnnouncement = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: "100vh",
      width: "100vw",
      color: "#302857",
      backgroundColor: "#e3e3e3",
    }}>
      <img style={{borderRadius: 20}} src="KelimeYardım_colored.jpg" height="200px" alt="KelimeYardım Logo" />
      <div style={{marginTop: 20}}>KelimeYardım hayatının sonuna gelmiştir.</div>
      <div>Destekleriniz için teşekkürler.</div>
    </div>
  );
}

export default connect(getToken, { updateToken, updateUser, resetToken, resetUser, updateTables, clearTables })(App);
//export default EndOfLifeAnnouncement;
