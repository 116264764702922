import { UPDATE_AVAILABLE_CHARS, RESET_TABLE_STATE } from "../actionTypes";

const initialState = {
  chars: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_AVAILABLE_CHARS: {
      const { chars } = action.payload;
      return {
        ...state,
        chars
      };
    }
    case RESET_TABLE_STATE: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}