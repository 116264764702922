import { UPDATE_TABLES, UPDATE_SINGLE_TABLE, CLEAR_TABLES, PRIORITIZE_SINGLE_TABLE, REMOVE_TABLE } from "../actionTypes";

const initialState = {
  tables: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TABLES: {
      const { tables } = action.payload;
      return {
        ...state,
        tables
      };
    }
    case UPDATE_SINGLE_TABLE: {
      const { table } = action.payload;
      const existingIdx = state.tables.findIndex((e) => e.tableName === table.tableName);
      const newTables = [...state.tables];
      if (-1 !== existingIdx) {
        newTables[existingIdx] = {
          ...newTables[existingIdx],
          ...table
        }
      } else {
        newTables.unshift(table);
      }
      return {
        ...state,
        tables: newTables
      }
    }
    case PRIORITIZE_SINGLE_TABLE: {
      const { tableName } = action.payload;
      const existingIdx = state.tables.findIndex((e) => e.tableName === tableName);
      if (-1 !== existingIdx) {
        const table = state.tables[existingIdx];
        const newTables = [...state.tables];
        newTables.splice(existingIdx, 1);
        newTables.unshift(table);
        return {
          ...state,
          tables: newTables
        }
      } else {
        console.log(`Received ${tableName} in PRIORITIZE_SINGLE_TABLE action which does not exist in our tables`);
        return state;
      }
    }
    case REMOVE_TABLE: {
      const { tableName } = action.payload;
      const existingIdx = state.tables.findIndex((e) => e.tableName === tableName);
      if (-1 !== existingIdx) {
        const newTables = [...state.tables];
        newTables.splice(existingIdx, 1);
        return {
          ...state,
          tables: newTables
        }
      } else {
        console.log(`Received ${tableName} in REMOVE_TABLE action which does not exist in our tables`);
        return state;
      }
    }
    case CLEAR_TABLES: {
      return initialState;
    }
    default:
      return state;
  }
}