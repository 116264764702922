import React from 'react';

class Solution extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const kelime = this.props.solution.foundWord.toLocaleUpperCase("tr");
        const puan = this.props.solution.reward;
        const moveCount = this.props.includeMoveCount?` (${this.props.solution.appliedMoves.length})`:"";
        return (
            <div className="solutionDiv" onClick={() => this.props.onClick(this.props.solution)}>
                <div>Kelime: {kelime}{moveCount}</div>
                <div>Puan: {puan}</div>
            </div>
        );
    }
}

export default Solution;
