import { OPEN_UNSAVED_TABLE, UPDATE_UNSAVED_TABLE, CLEAR_UNSAVED_TABLE } from "../actionTypes";

const initialState = {
  unsavedTableExists: true,
  unsavedTable: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_UNSAVED_TABLE: {
      return {
        ...state,
        unsavedTableExists: true,
        unsavedTable: undefined
      };
    }
    case UPDATE_UNSAVED_TABLE: {
      const { table } = action.payload;
      return {
        ...state,
        unsavedTable: table
      };
    }
    case CLEAR_UNSAVED_TABLE: {
      return {
        ...state,
        unsavedTableExists: false,
        unsavedTable: undefined
      }
    }
    default:
      return state;
  }
}