import { UPDATE_USER, RESET_USER } from "../actionTypes";

const initialState = {
  user: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case RESET_USER: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}