export const UPDATE_TABLE_STATE = "UPDATE_TABLE_STATE";
export const RESET_TABLE_STATE = "RESET_TABLE_STATE";
export const UPDATE_AVAILABLE_CHARS = "UPDATE_AVAILABLE_CHARS";
export const UPDATE_JOKER_LIST = "UPDATE_JOKER_LIST";
export const UPDATE_TRIPLE_STAR_INDEX = "UPDATE_TRIPLE_STAR_INDEX";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const RESET_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER = "UPDATE_USER";
export const RESET_USER = "RESET_USER";
export const UPDATE_KEY = "UPDATE_KEY";
export const UPDATE_TABLES = "UPDATE_TABLES";
export const UPDATE_SINGLE_TABLE = "UPDATE_SINGLE_TABLE";
export const CLEAR_TABLES = "CLEAR_TABLES";
export const SET_ACTIVE_TABLE_NAME = "SET_ACTIVE_TABLE_NAME";
export const CLEAR_ACTIVE_TABLE_NAME = "CLEAR_ACTIVE_TABLE_NAME";
export const OPEN_UNSAVED_TABLE = "OPEN_UNSAVED_TABLE";
export const UPDATE_UNSAVED_TABLE = "UPDATE_UNSAVED_TABLE";
export const CLEAR_UNSAVED_TABLE = "CLEAR_UNSAVED_TABLE";
export const PRIORITIZE_SINGLE_TABLE = "PRIORITIZE_SINGLE_TABLE";
export const REMOVE_TABLE = "REMOVE_TABLE";