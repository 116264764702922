import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { getTableState } from './redux/selectors';

const ShowRemainingChars = (props) => {

    const [visible, setVisible] = useState(false);

    const clicked = () => {
        setVisible(true);
    }

    const outsideClicked = (e) => {
        e.stopPropagation();
        setVisible(false);
    }

    return (
        <button type="button" className="btn btn-primary constantSizeButton" onClick={clicked}>
            KALAN HARFLER
            {visible ? <RemainingCharsView outsideClickedCallback={outsideClicked} /> : null}
        </button>
    );
}

const RemainingCharsViewBase = (props) => {

    const initialChars = {
        a: 12,
        b: 2,
        c: 2,
        ç: 2,
        d: 2,
        e: 8,
        f: 1,
        g: 1,
        ğ: 1,
        h: 1,
        ı: 4,
        i: 7,
        j: 1,
        k: 7,
        l: 7,
        m: 4,
        n: 5,
        o: 3,
        ö: 1,
        p: 1,
        r: 6,
        s: 3,
        ş: 2,
        t: 5,
        u: 3,
        ü: 2,
        v: 1,
        y: 2,
        z: 2,
        x: 2
    };

    let remainingChars = {
        a: 12,
        b: 2,
        c: 2,
        ç: 2,
        d: 2,
        e: 8,
        f: 1,
        g: 1,
        ğ: 1,
        h: 1,
        ı: 4,
        i: 7,
        j: 1,
        k: 7,
        l: 7,
        m: 4,
        n: 5,
        o: 3,
        ö: 1,
        p: 1,
        r: 6,
        s: 3,
        ş: 2,
        t: 5,
        u: 3,
        ü: 2,
        v: 1,
        y: 2,
        z: 2,
        x: 2
    };

    props.chars.forEach(e => {
        const key = e.toLocaleLowerCase('tr');
        if (remainingChars[key] != undefined) {
            --remainingChars[key];
        }
    });

    props.tableState.split("\n").join("").split("").forEach((e, idx) => {
        if (e !== '-') {
            let key = e.toLocaleLowerCase('tr');
            if (props.jokerList.includes(idx)) {
                key = 'x';
            }
            if (remainingChars[key] != undefined) {
                --remainingChars[key];
            }
        }
    });

    const result = Object.keys(initialChars).map((key, idx) => {
        const charClassName = 0 === remainingChars[key]?"singleChar charsUsedUp":"singleChar";
        return (
            <div className="singleCharRemainingContainer" key={idx}>
                <div className={charClassName}>
                    <span>
                        {key.toLocaleUpperCase('tr')}
                    </span>
                </div>
                <div className="singleCharCount">
                    {remainingChars[key]}/{initialChars[key]}
                </div>
            </div>
        )
    });

    return (
        <div className="fixedFullPage centeredParent" onClick={(e) => props.outsideClickedCallback && props.outsideClickedCallback(e)}>
            <div className="showRemainingCharsView" onClick={(e) => { e.stopPropagation() }}>
                <div className="remainingCharsContainer">
                    <div style={{ paddingTop: "5px", color: "#302857", paddingBottom: "10px", fontWeight: "700" }}>
                        Kalan Harfler
                    </div>
                    <div className="remainingCharsGrid">
                        {result}
                    </div>
                </div>
            </div>
        </div >
    );
}

const RemainingCharsView = connect(getTableState)(RemainingCharsViewBase);

export default ShowRemainingChars;