import React from 'react';
import './css/Style.css';

class Block extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = props.onChange;
        this.state = {
            value: props.blockChar == undefined ? "" : props.blockChar,
            isJoker: props.blockIsJoker
        };

        this.timer = 0;
        this.prevent = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.keyDownCallback = this.keyDownCallback.bind(this);
        this.isBlockEmpty = this.isBlockEmpty.bind(this);
        this.isSpecialBlock = this.isSpecialBlock.bind(this);
    }

    handleChange(event) {
        let candidate = event.target.value;
        if (candidate == "") {
            this.setState({ value: "" });
        }
        candidate = candidate.replace(this.state.value, "");
        candidate = candidate.split("").pop();
        if (candidate) {
            candidate = candidate.toLocaleLowerCase('tr');
            if (candidate == candidate.match(/^[a-zığüşöç]+$/i)) {
                this.setState({ value: candidate.toLocaleUpperCase('tr') });
            }
        }
    }

    handleDoubleClick(event) {
        clearTimeout(this.timer);
        this.prevent = true;
        if (this.props.solutionChar == undefined) {
            if (!this.isBlockEmpty()) {
                this.setState({ isJoker: !this.state.isJoker });
            } else {
                if (!this.isSpecialBlock()) {
                    this.props.emptyBlockDoubleClicked();
                }
            }
        }
    }

    keyDownCallback(event) {
        console.log(`pressed ${event.key}`)
        if (event.key == 'Delete' || event.key == 'Backspace') {
            this.setState({ value: "" });
        }
    }

    handleClick(event) {
        let me = this;
        this.timer = setTimeout(function () {
            if (!me.prevent) {
                me.props.blockClicked();
            }
            me.prevent = false;
        }, 200);
    }

    isBlockEmpty() {
        return this.state.value == "";
    }

    isSpecialBlock() {
        switch (this.props.specialSquareType) {
            case "D":
            case "d":
            case "T":
            case "t":
                return true;
            default:
                return false;
        }
    }

    render() {
        this.onChange(this.state.value, this.state.isJoker);
        let className = ["blockTextarea"];
        if (this.isBlockEmpty()) {
            switch (this.props.specialSquareType) {
                case "D":
                    className.push("kelime2");
                    break;
                case "d":
                    className.push("harf2");
                    break;
                case "T":
                    className.push("kelime3");
                    break;
                case "t":
                    className.push("harf3");
                    break;
                default:
                    if (this.props.isTripleStar) {
                        className.push("tripleStar");
                    }
            }
        }
        if (this.props.isClicked) {
            className.push("clicked");
        }
        if (this.props.solutionChar != undefined) {
            return (
                <div style={{ position: "relative" }}>
                    <textarea
                        type="text"
                        className="blockTextareaSolution"
                        value={this.props.solutionChar}
                        readOnly={true}
                    />
                    {this.props.solutionIsJoker? <JokerDot /> : null}
                </div>
            );
        } else {
            if (this.state.value !== "") {
                className.push("blockTextarea--moveApplied");
            }
            className = className.join(" ");
            return (
                <div style={{ position: "relative" }}>
                    <textarea
                        type="text"
                        className={className}
                        value={this.state.value}
                        onChange={this.handleChange}
                        onDoubleClick={this.handleDoubleClick}
                        onClick={this.handleClick}
                        autoFocus={this.props.isClicked ? true : false}
                        onKeyDown={this.keyDownCallback}
                    />
                    {this.state.isJoker && !this.isBlockEmpty() ? <JokerDot /> : null}
                </div>
            );
        }
    }
}

const JokerDot = () => {
    return (
        <div className="jokerDot" />
    );
}

export default Block;
