import { UPDATE_TABLE_STATE, RESET_TABLE_STATE, UPDATE_TRIPLE_STAR_INDEX, UPDATE_JOKER_LIST } from "../actionTypes";

let EMPTY_TABLE_STATE = ` ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
                          ---------------
`.split("\n").map(val => val.trim()).join("\n");

const initialState = {
  tableState: EMPTY_TABLE_STATE,
  jokerList: [],
  tripleStarIndex: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TABLE_STATE: {
      const { tableState } = action.payload;
      return {
        ...state,
        tableState
      };
    }
    case UPDATE_JOKER_LIST: {
      const { jokerList } = action.payload;
      return {
        ...state,
        jokerList
      };
    }
    case UPDATE_TRIPLE_STAR_INDEX: {
      const { tripleStarIndex } = action.payload;
      return {
        ...state,
        tripleStarIndex
      };
    }
    case RESET_TABLE_STATE: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}