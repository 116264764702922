import React from 'react';

const AreYouSurePage = (props) => {

    const outsideClicked = (e) => {
        if (props.callback) {
            props.callback(false);
            e.stopPropagation();
        }
    };

    const buttonClicked = (e, choice) => {
        if (props.callback) {
            props.callback(choice);
            e.stopPropagation();
        }
    };

    return (
        <div className="fixedFullPage centeredParent" onClick={outsideClicked}>
            <div className="areYouSureContainer" onClick={(e) => { e.stopPropagation() }}>
                <div className="areYouSureTitleContainer">{props.title}</div>
                <div className="rowFlex containerWidth">
                    <div className="AreYouSureOption" onClick={e => buttonClicked(e, true)}>Evet</div>
                    <div className="AreYouSureOption" onClick={e => buttonClicked(e, false)}>Hayır</div>
                </div>
            </div>
        </div>
    );
}

export default AreYouSurePage;