import React from 'react';
import Solution from './Solution';

class SolutionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabIdx: 0
        };
        this.tabClicked = this.tabClicked.bind(this);
    }

    tabClicked(idx) {
        console.log("idx clicked:" + idx);
        this.setState({
            selectedTabIdx: idx
        })
    }

    render() {
        let rewardOrderedSolutions = undefined;
        if (this.props.rewardOrderedSolutions) {
            rewardOrderedSolutions = this.props.rewardOrderedSolutions.map((sol, idx) => <Solution
                key={idx}
                solution={sol}
                onClick={this.props.onSolutionClick}
            />);
        }

        let moveOrderedSolutions = undefined;
        if (this.props.moveOrderedSolutions) {
            moveOrderedSolutions = this.props.moveOrderedSolutions.map((sol, idx) => <Solution
                key={idx}
                solution={sol}
                includeMoveCount={true}
                onClick={this.props.onSolutionClick}
            />);
        }

        const loading = <div className="loadingContainer">
            <div className="loadingCircle" />
        </div>;

        return (
            <div className="solutionPageDiv" id="solutionPage">
                <div className="tabHeader">
                    <div
                        onClick={() => this.tabClicked(0)}
                        className={this.state.selectedTabIdx == 1 ? "tab passive borderRightBottom" : "tab"}
                    >
                        Puan Sıralama
                    </div>
                    <div
                        onClick={() => this.tabClicked(1)}
                        className={this.state.selectedTabIdx == 0 ? "tab passive borderLeftBottom" : "tab"}
                    >
                        Harf Sıralama
                    </div>
                </div>
                <div className="solutionContainer">
                    {this.props.isLoading ? loading : this.state.selectedTabIdx == 0 ? rewardOrderedSolutions : moveOrderedSolutions}
                </div>
            </div>
        );
    }
}

export default SolutionPage;
