import React, { useState, useEffect} from 'react';
import { connect } from "react-redux";
import { getLoggedInInfo } from './redux/selectors';
import { timeoutFetch } from './utilities';
import LoadingPage from "./LoadingPage";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import API_URL from './backend';
import LoadableTable from './LoadableTable';

const UserTables = (props) => {
    const [ready, setReady] = useState(false);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        loadTables();
    }, []);

    const loadTables = async () => {
        try {
            const response = await timeoutFetch(10000, fetch(`${API_URL}/table/get`, {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${props.token}`
                }
            }));

            const responseBody = await response.json();
            setTables(responseBody.data.tables);
            setReady(true);
        } catch (err) {
            console.log(err);
            setTables([]);
            setReady(true);
            NotificationManager.warning('Bağlantı hatası', 'Tahtalar getirilemedi', 2000);
        }

    };

    const getTableComponents = () => {
        if (tables.length > 0) {
            return tables.map((table, idx) => <LoadableTable tableInfo={table} key={idx} closeDropDownCallback={props.closeDropDownCallback}/>);
        } else {
            return (<div className="DropDownItem">Hiç tahtanız bulunmuyor</div>);
        }
    }

    return ready ? getTableComponents() : <div><LoadingPage /></div>;
};

export default connect(
    getLoggedInInfo,
    null
)(UserTables);