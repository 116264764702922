import React from 'react';
import options from './images/options.svg';
import DropDownMenu from './DropDownMenu';

class UserActionsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true
        };
        this.clicked = this.clicked.bind(this);
    }

    clicked(event) {
        this.setState({
            hidden: this.state.hidden ? false : true
        })
    }

    closeDropDown = () => {
        this.setState({ hidden: true });
    }

    render() {
        return <div className="leftRightPadding" >
            <img src={options} alt="Kullanıcı İşlemleri" style={{ height: "25px", cursor: "pointer" }} onClick={this.clicked} />
            {this.state.hidden ? null : <DropDownMenu closeDropDownCallback={this.closeDropDown}/>}
        </div>;
    }
}

export default UserActionsSection;