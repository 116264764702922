import React from 'react';
import { connect } from "react-redux";
import Table from './Table';
import InputPage from './InputPage';
import SolutionPage from './SolutionPage';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { getTableState } from './redux/selectors';
import { updateTableState, updateJokerList, updateTripleStarIndex, resetTableState, updateAvailableChars } from "./redux/actions";
import API_URL from './backend';

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            solutionObject: {},
            selectedSolution: undefined,
            isLoading: false,
            focusIndex: undefined,
            textInput: ""
        };
        this.initialState = this.state;
        this.tableStateUpdated = this.tableStateUpdated.bind(this);
        this.httpGetAsync = this.httpGetAsync.bind(this);
        this.solveButtonOnClick = this.solveButtonOnClick.bind(this);
        this.apiResponseCallback = this.apiResponseCallback.bind(this);
        this.showSolution = this.showSolution.bind(this);
        this.clearSolutionButtonCallback = this.clearSolutionButtonCallback.bind(this);
        this.clearTableButtonCallback = this.clearTableButtonCallback.bind(this);
        this.applySelectedSolutionCallback = this.applySelectedSolutionCallback.bind(this);
        this.charsUpdatedCallback = this.charsUpdatedCallback.bind(this);
        this.emptyBlockDoubleClicked = this.emptyBlockDoubleClicked.bind(this);
        this.blockClicked = this.blockClicked.bind(this);
        this.getStarPosition = this.getStarPosition.bind(this);
        this.apiResponseTimeoutCallback = this.apiResponseTimeoutCallback.bind(this);
        this.apiFailedCallback = this.apiFailedCallback.bind(this);
        this.textInputUpdatedCallback = this.textInputUpdatedCallback.bind(this);
        this.insertToRightButtonClicked = this.insertToRightButtonClicked.bind(this);
        this.insertToDownButtonClicked = this.insertToDownButtonClicked.bind(this);
        this.getCoordinatesFromIdx = this.getCoordinatesFromIdx.bind(this);
        this.getUpdatedTableStateForMoves = this.getUpdatedTableStateForMoves.bind(this);
        this.getFormattedChars = this.getFormattedChars.bind(this);
    }

    tableStateUpdated(table, jokerList) {
        this.props.updateTableState(table);
        this.props.updateJokerList(jokerList);
    }

    solveButtonOnClick() {
        const jokerCount = this.getFormattedChars().filter((e) => e === "joker").length;
        if (jokerCount <= 2) {
            this.httpGetAsync(this.apiResponseCallback, this.apiFailedCallback, this.apiResponseTimeoutCallback);
        } else {
            NotificationManager.warning('2 taneden fazla Joker kullanılamaz.', '', 2000);
        }
    }

    getFormattedChars() {
        return this.props.chars.map(e => e.toLocaleLowerCase('tr')).map(e => e == 'x' ? "joker" : e);
    }

    clearSolutionButtonCallback() {
        this.setState({
            selectedSolution: undefined,
            focusIndex: undefined
        });
    }

    charsUpdatedCallback(chars) {
        this.props.updateAvailableChars(chars);
    }

    clearTableButtonCallback() {
        this.props.resetTableState();
        this.setState(this.initialState);
    }

    applySelectedSolutionCallback() {
        if (this.state.selectedSolution != undefined) {
            let temp = this.props.tableState.split("\n").join("").split("");
            let tempJokerList = this.props.jokerList;
            let success = true;
            for (const move of this.state.selectedSolution.appliedMoves) {
                const pos = (move.row - 1) * 15 + (move.col - 1);
                if (temp[pos] != "-") {
                    success = false;
                    break;
                } else {
                    temp[pos] = move.character;
                    if (move.isJoker) {
                        tempJokerList.push(pos);
                    }
                }
            }
            if (success) {
                temp = temp.map(e => e.toLocaleUpperCase('tr'));
                const arr = [];
                for (let x = 0; x < 15; ++x) {
                    let str = "";
                    for (let y = 0; y < 15; ++y) {
                        const idx = x * 15 + y;
                        str += temp[idx];
                    }
                    arr.push(str);
                }
                const newTableState = arr.join("\n");

                let previousChars = this.props.chars;

                for (const move of this.state.selectedSolution.appliedMoves) {
                    if (move.isJoker) {
                        const idx = previousChars.indexOf('X');
                        previousChars.splice(idx, 1);
                    } else {
                        const idx = previousChars.indexOf(move.character.toLocaleUpperCase('tr'));
                        previousChars.splice(idx, 1);
                    }
                }

                this.props.updateTableState(newTableState);
                this.props.updateJokerList(tempJokerList);
                this.props.updateAvailableChars(previousChars);
                this.setState({
                    solutionObject: {},
                    selectedSolution: undefined,
                    focusIndex: undefined
                });
            } else {
                console.log("Table state and solution is not compatible. Not applying solution");
            }
        }

    }

    emptyBlockDoubleClicked(blockIdx) {
        this.props.updateTripleStarIndex(blockIdx == this.props.tripleStarIndex ? undefined : blockIdx)
        this.setState({
            focusIndex: blockIdx
        });
    }

    blockClicked(blockIdx) {
        this.setState({
            focusIndex: blockIdx
        });
    }

    apiResponseCallback(response) {
        const solutionObject = JSON.parse(response);
        this.setState({
            solutionObject,
            isLoading: false,
            focusIndex: undefined
        });
    }

    apiResponseTimeoutCallback() {
        NotificationManager.warning('Sunucudan yanıt alınamadı', 'Bağlantı hatası', 2000);
        this.setState({
            solutionObject: {},
            selectedSolution: undefined,
            isLoading: false,
            focusIndex: undefined
        });
    }

    apiFailedCallback() {
        NotificationManager.error('Bunun olmaması lazımdı :(', 'Çözemedik', 2000);
        this.setState({
            solutionObject: {},
            selectedSolution: undefined,
            isLoading: false,
            focusIndex: undefined
        });
    }

    httpGetAsync(callback, failCallback, timeoutCallback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.timeout = 10000; // 10 sec
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState == 4) {
                switch (xmlHttp.status) {
                    case 200:
                        callback(xmlHttp.responseText);
                        break;
                    case 0:
                        timeoutCallback();
                        break;
                    default:
                        failCallback();
                }
            }
        }
        xmlHttp.ontimeout = function () {
            timeoutCallback();
        }
        xmlHttp.open("POST", `${API_URL}`, true); // true for asynchronous
        xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlHttp.send(JSON.stringify({
            table: this.props.tableState,
            jokerArray: this.props.jokerList.map(this.getCoordinatesFromIdx),
            chars: this.getFormattedChars(),
            starPosition: this.getStarPosition()
        }));
        this.setState({
            selectedSolution: undefined,
            isLoading: true
        })
    }

    getStarPosition() {
        let starPosition = {
            x: this.props.tripleStarIndex % 15 + 1,
            y: Math.floor(this.props.tripleStarIndex / 15) + 1
        };
        if (undefined == this.props.tripleStarIndex) {
            starPosition = {
                x: undefined,
                y: undefined
            }
        }
        return starPosition;
    }

    getCoordinatesFromIdx(idx) {
        return {
            col: idx % 15 + 1,
            row: Math.floor(idx / 15) + 1
        }
    }

    showSolution(solution) {
        console.log(solution);
        this.setState({
            selectedSolution: solution
        });
    }

    textInputUpdatedCallback(text) {
        this.setState({
            textInput: text.toLocaleUpperCase('tr')
        });
    }

    insertToRightButtonClicked() {
        if (this.state.focusIndex !== undefined && this.state.textInput) {
            let coordinates = this.getCoordinatesFromIdx(this.state.focusIndex);
            let moves = [];
            for (let i = 0; i < this.state.textInput.length; ++i) {
                moves.push({
                    row: coordinates.row,
                    col: coordinates.col + i,
                    character: this.state.textInput.charAt(i)
                });
            }

            const { success, newTableState } = this.getUpdatedTableStateForMoves(moves);
            if (success) {
                this.props.updateTableState(newTableState);
                this.setState({
                    solutionObject: {},
                    selectedSolution: undefined,
                    focusIndex: undefined,
                    textInput: ""
                });
            } else {
                NotificationManager.warning('Taşlar bu şekilde sağa doğru yerleştirilemez', '', 2000);
            }
        } else if (this.state.focusIndex === undefined) {
            NotificationManager.warning('Kelime yerleştirmek için bir kare seçmelisiniz', '', 2000);
        } else {
            NotificationManager.warning('Yerleştirilecek kelime seçmelisiniz', '', 2000);
        }
    }

    insertToDownButtonClicked() {
        if (this.state.focusIndex !== undefined && this.state.textInput) {
            let coordinates = this.getCoordinatesFromIdx(this.state.focusIndex);
            let moves = [];
            for (let i = 0; i < this.state.textInput.length; ++i) {
                moves.push({
                    row: coordinates.row + i,
                    col: coordinates.col,
                    character: this.state.textInput.charAt(i)
                });
            }

            const { success, newTableState } = this.getUpdatedTableStateForMoves(moves);
            if (success) {
                this.props.updateTableState(newTableState);
                this.setState({
                    solutionObject: {},
                    selectedSolution: undefined,
                    focusIndex: undefined,
                    textInput: ""
                });
            } else {
                NotificationManager.warning('Taşlar bu şekilde aşağı doğru yerleştirilemez', '', 2000);
            }
        } else if (this.state.focusIndex === undefined) {
            NotificationManager.warning('Kelime yerleştirmek için bir kare seçmelisiniz', '', 2000);
        } else {
            NotificationManager.warning('Yerleştirilecek kelime seçmelisiniz', '', 2000);
        }
    }

    getUpdatedTableStateForMoves(moves) {
        let temp = this.props.tableState.split("\n").join("").split("");
        const failResult = { success: false };

        for (let move of moves) {
            if (move.row < 1 || move.row > 15 || move.col < 1 || move.col > 15) {
                return failResult;
            }
        }

        for (const move of moves) {
            const pos = (move.row - 1) * 15 + (move.col - 1);
            if (temp[pos] != "-" && temp[pos] != move.character) {
                return failResult;
            } else {
                temp[pos] = move.character;
            }
        }

        temp = temp.map(e => e.toLocaleUpperCase('tr'));
        const arr = [];
        for (let x = 0; x < 15; ++x) {
            let str = "";
            for (let y = 0; y < 15; ++y) {
                const idx = x * 15 + y;
                str += temp[idx];
            }
            arr.push(str);
        }
        const newTableState = arr.join("\n");

        return {
            success: true,
            newTableState
        };
    }

    render() {
        return (
            <div className="centeredDiv">
                <div className="horizontalFlex">
                    <Table
                        tableStateUpdated={this.tableStateUpdated}
                        lastTableState={this.props.tableState}
                        lastTableJokerList={this.props.jokerList}
                        movesToBeApplied={this.state.selectedSolution != undefined ? this.state.selectedSolution.appliedMoves : undefined}
                        emptyBlockDoubleClicked={this.emptyBlockDoubleClicked}
                        blockClicked={this.blockClicked}
                        tripleStarIndex={this.props.tripleStarIndex}
                        focusIndex={this.state.focusIndex}
                        key={JSON.stringify({
                            selectedSolution: this.state.selectedSolution,
                            tableState: this.props.tableState,
                            tripleStarIndex: this.props.tripleStarIndex,
                            focusIndex: this.state.focusIndex
                        })}
                    />
                    <InputPage
                        buttonCallback={this.solveButtonOnClick}
                        charsUpdatedCallback={this.charsUpdatedCallback}
                        chars={this.props.chars}
                        clearSolutionButtonCallback={this.clearSolutionButtonCallback}
                        clearTableButtonCallback={this.clearTableButtonCallback}
                        applySelectedSolutionCallback={this.applySelectedSolutionCallback}
                        textInputUpdatedCallback={this.textInputUpdatedCallback}
                        textInput={this.state.textInput}
                        insertToRightButtonClicked={this.insertToRightButtonClicked}
                        insertToDownButtonClicked={this.insertToDownButtonClicked}
                    />
                    <SolutionPage
                        key={JSON.stringify({
                            rewardOrderedSolutions: this.state.solutionObject.rewardOrderedSolutions,
                            moveOrderedSolutions: this.state.solutionObject.moveOrderedSolutions
                        })}
                        rewardOrderedSolutions={this.state.solutionObject.rewardOrderedSolutions}
                        moveOrderedSolutions={this.state.solutionObject.moveOrderedSolutions}
                        isLoading={this.state.isLoading}
                        onSolutionClick={this.showSolution}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = getTableState;

export default connect(
    mapStateToProps,
    { updateTableState, updateJokerList, updateTripleStarIndex, resetTableState, updateAvailableChars }
)(MainPage);