import { UPDATE_KEY } from "../actionTypes";

const initialState = {
  key: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_KEY: {
      const { key } = action.payload;
      return {
        ...state,
        key
      };
    }
    default:
      return state;
  }
}