import React from 'react';
import Block from './Block.js'

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.blockOnChange = this.blockOnChange.bind(this);
        this.notifyParent = this.notifyParent.bind(this);
        this.getSpecialSquaresArray = this.getSpecialSquaresArray.bind(this);
        this.setUpInitialState = this.setUpInitialState.bind(this);
        this.blocks = [];
        this.blockChars = [];
        this.jokerList = [];
        this.setUpInitialState();
    }

    getSpecialSquaresArray() {
        let SPECIAL_SQUARE_TABLE = `--T--d---d--T--
                                    -t----d-d----t-
                                    T------D------T
                                    ---D-------D---
                                    ----t-----t----
                                    d----d---d----d
                                    -d----d-d----d-
                                    --D----D----D--
                                    -d----d-d----d-
                                    d----d---d----d
                                    ----t-----t----
                                    ---D-------D---
                                    T------D------T
                                    -t----d-d----t-
                                    --T--d---d--T--
        `;
        SPECIAL_SQUARE_TABLE = SPECIAL_SQUARE_TABLE.split('\n').map(val => val.trim()).filter(val => val.length == 15).join('').split('');
        return SPECIAL_SQUARE_TABLE;
    }

    setUpInitialState() {
        let initialState = this.props.lastTableState;
        let initialJokerList = this.props.lastTableJokerList;
        let moves = this.props.movesToBeApplied;
        let moveCharArr = [];
        let moveCharArrIsJoker = [];
        let specialSquaresArray = this.getSpecialSquaresArray();
        for (let i = 0; i < 15 * 15; ++i) {
            moveCharArr.push(undefined);
            moveCharArrIsJoker.push(undefined);
        }
        if (moves != undefined) {
            for (let i = 0; i < moves.length; ++i) {
                let pos = (moves[i].row - 1) * 15 + (moves[i].col - 1);
                moveCharArr[pos] = moves[i].character.toLocaleUpperCase('tr');
                moveCharArrIsJoker[pos] = moves[i].isJoker;
            }
        }
        if (initialState == undefined) {
            for (let i = 0; i < 15 * 15; ++i) {
                this.blocks.push(<td key={i}>
                    <Block
                        blockIsJoker={initialJokerList.includes(i)}
                        onChange={(val, isJoker) => this.blockOnChange(i, val, isJoker)}
                        solutionChar={moveCharArr[i]}
                        solutionIsJoker={moveCharArrIsJoker[i]}
                        specialSquareType={specialSquaresArray[i]}
                        emptyBlockDoubleClicked={() => this.props.emptyBlockDoubleClicked(i)}
                        blockClicked={() => this.props.blockClicked(i)}
                        isTripleStar={this.props.tripleStarIndex == i}
                        isClicked={this.props.focusIndex == i}
                    />
                </td>);
                this.blockChars.push("");
            }
        } else {
            initialState = initialState.split("\n").join("");
            for (let i = 0; i < 15 * 15; ++i) {
                let candidateChar = initialState.charAt(i);
                candidateChar = candidateChar == "-" ? "" : candidateChar;
                this.blocks.push(<td key={i}>
                    <Block
                        blockChar={candidateChar}
                        blockIsJoker={initialJokerList.includes(i)}
                        onChange={(val, isJoker) => this.blockOnChange(i, val, isJoker)}
                        solutionChar={moveCharArr[i]}
                        solutionIsJoker={moveCharArrIsJoker[i]}
                        specialSquareType={specialSquaresArray[i]}
                        emptyBlockDoubleClicked={() => this.props.emptyBlockDoubleClicked(i)}
                        blockClicked={() => this.props.blockClicked(i)}
                        isTripleStar={this.props.tripleStarIndex == i}
                        isClicked={this.props.focusIndex == i}
                    />
                </td>);
                this.blockChars.push("");
            }
        }
    }

    blockOnChange(idx, newChar, isJoker) {
        this.blockChars[idx] = newChar;
        if (isJoker) {
            if (!this.jokerList.includes(idx)) {
                this.jokerList.push(idx);
            }
        } else {
            const pos = this.jokerList.indexOf(idx);
            if (pos > -1) {
                this.jokerList.splice(pos, 1);
            }
        }
        this.notifyParent();
    }

    notifyParent() {
        const arr = [];
        for (let x = 0; x < 15; ++x) {
            let str = "";
            for (let y = 0; y < 15; ++y) {
                const idx = x * 15 + y;
                let addedStr = this.blockChars[idx] == "" ? "-" : this.blockChars[idx];
                str += addedStr;
            }
            arr.push(str);
        }
        const table = arr.join("\n");
        this.props.tableStateUpdated(table, this.jokerList);
    }

    render() {
        let rowArray = [];
        for (let i = 0; i < 15; ++i) {
            rowArray.push(this.blocks.slice(i * 15, i * 15 + 15));
        }
        return (
            <div id="boardTable">
                <table>
                    <tbody>
                        <tr>{rowArray[0]}</tr>
                        <tr>{rowArray[1]}</tr>
                        <tr>{rowArray[2]}</tr>
                        <tr>{rowArray[3]}</tr>
                        <tr>{rowArray[4]}</tr>
                        <tr>{rowArray[5]}</tr>
                        <tr>{rowArray[6]}</tr>
                        <tr>{rowArray[7]}</tr>
                        <tr>{rowArray[8]}</tr>
                        <tr>{rowArray[9]}</tr>
                        <tr>{rowArray[10]}</tr>
                        <tr>{rowArray[11]}</tr>
                        <tr>{rowArray[12]}</tr>
                        <tr>{rowArray[13]}</tr>
                        <tr>{rowArray[14]}</tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
