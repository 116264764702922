import { SET_ACTIVE_TABLE_NAME, CLEAR_ACTIVE_TABLE_NAME } from "../actionTypes";

const initialState = {
  activeTableName: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_TABLE_NAME: {
      const { activeTableName } = action.payload;
      return {
        ...state,
        activeTableName
      };
    }
    case CLEAR_ACTIVE_TABLE_NAME: {
      return initialState;
    }
    default:
      return state;
  }
}