import React from 'react';
import { connect } from "react-redux";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { updateToken, updateUser, clearActiveTableName } from "./redux/actions";
import LoadingPage from "./LoadingPage";
import { timeoutFetch } from "./utilities";
import API_URL from './backend';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false
        }
        this.tryLogin = this.tryLogin.bind(this);
        this.usernameUpdated = this.usernameUpdated.bind(this);
        this.passwordUpdated = this.passwordUpdated.bind(this);
        this.keyDownCallback = this.keyDownCallback.bind(this);
    }

    async tryLogin() {
        this.setState({ loading: true });
        const user = {
            username: this.state.username,
            password: this.state.password
        };
        try {
            const response = await timeoutFetch(10000, fetch(`${API_URL}/user/login`, {
                method: 'POST',
                //mode: 'cors', // no-cors, *cors, same-origin
                //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user })
            }));

            const responseBody = await response.json();

            if (responseBody.status === 'success') {
                NotificationManager.success('Giriş yapıldı', 'Başarılı', 2000);
                const { user, token } = responseBody.data;
                this.props.updateUser(user);
                this.props.updateToken(token);
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', token);
                this.props.clearActiveTableName();
                this.props.history.push(`/`, );
            } else {
                NotificationManager.warning(responseBody.reason, 'Giriş yapılamadı', 2000);
            }
        } catch (err) {
            console.log({err});
            NotificationManager.warning(err.message, 'Sunucu ile bağlantı sırasında hata oluştu', 2000);
        }
        this.setState({ loading: false });
    }

    usernameUpdated(event) {
        this.setState({
            username: event.target.value
        });
    }

    passwordUpdated(event) {
        this.setState({
            password: event.target.value
        });
    }

    keyDownCallback(event) {
        if (event.key === 'Enter') {
            this.tryLogin();
        }
    }

    render() {
        let result = (
            <div className="LoginBox">
                <div className="LoginLabel">Giriş Yap</div>
                <input type="text" className="input-group-text" placeholder="Kullanıcı adı" value={this.state.username} onChange={this.usernameUpdated} />
                <input type="password" className="input-group-text" placeholder="Şifre" value={this.state.password} onChange={this.passwordUpdated} />
                <button type="button" className="btn btn-primary" onClick={this.tryLogin}>Giriş</button>
            </div>
        );
        if (this.state.loading) {
            result = <LoadingPage />
        }
        return (
            <div className="LoginPageContainer" onKeyDown={this.keyDownCallback}>
                {result}
            </div>
        );
    }
};

export default connect(
    null,
    { updateToken, updateUser, clearActiveTableName }
)(LoginPage);