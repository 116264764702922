import React from 'react';
import InfoBoxContainer from './InfoBoxContainer';
import AreYouSurePage from './AreYouSurePage';
import ShowRemainingChars from './ShowRemainingChars';

class InputPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAreYouSure: false
        }
        this.charsHandleChange = this.charsHandleChange.bind(this);
        this.inputTextHandleChange = this.inputTextHandleChange.bind(this);
    }

    charsHandleChange(event) {
        let candidate = event.target.value;
        candidate = candidate.toLocaleLowerCase('tr');
        candidate = candidate.split('');
        candidate = candidate.filter(elem => elem.length === 1 && elem.match(/^[a-zığüşöç]{1}$/g) !== null);
        let charsInFormat = candidate;
        candidate = candidate.join(' ');
        candidate = candidate.toLocaleUpperCase('tr');
        if (candidate.length < 14) {
            this.props.charsUpdatedCallback(charsInFormat.map(e => e.toLocaleUpperCase('tr')));
        }
    }

    inputTextHandleChange(event) {
        let candidate = event.target.value;
        candidate = candidate.toLocaleUpperCase('tr');
        this.props.textInputUpdatedCallback(candidate);
    }

    clearTableButtonClicked = () => {
        this.setState({
            showAreYouSure: true
        });
    }

    areYouSureCallback = (result) => {
        if(result){
            this.props.clearTableButtonCallback();
        }
        this.setState({
            showAreYouSure: false
        });
    }

    render() {
        return (
            <div className="verticalFlex" id="inputPage">
                <InfoBoxContainer />
                <textarea className="charsTextarea" type="text" placeholder="Taşlarınız" value={this.props.chars.join(" ")} onChange={this.charsHandleChange} />
                <button type="button" className="btn btn-primary constantSizeButton" onClick={this.props.buttonCallback}>ÇÖZ</button>
                <button type="button" className="btn btn-primary constantSizeButton" onClick={this.props.applySelectedSolutionCallback}>ÇÖZÜMÜ UYGULA</button>
                <button type="button" className="btn btn-primary constantSizeButton" onClick={this.props.clearSolutionButtonCallback}>ÇÖZÜM TEMİZLE</button>
                <button type="button" className="btn btn-primary constantSizeButton" onClick={this.clearTableButtonClicked}>TAHTAYI TEMİZLE</button>
                {this.state.showAreYouSure?<AreYouSurePage callback={this.areYouSureCallback} title={`Tahta temizlenecek. Emin misiniz?`} />:null}
                <textarea className="charsTextarea" type="text" placeholder="Girilecek Kelime" value={this.props.textInput} onChange={this.inputTextHandleChange} />
                <div className="buttonContainer">
                    <button type="button" className="btn btn-primary halfConstantSizeButton" onClick={this.props.insertToRightButtonClicked}>SAĞA</button>
                    <button type="button" className="btn btn-primary halfConstantSizeButton" onClick={this.props.insertToDownButtonClicked}>AŞAĞIYA</button>
                </div>
                <ShowRemainingChars/>
            </div>
        );
    }
}

export default InputPage;
