export const checkUsernameValidity = username => {
  return username !== undefined && username !== null && typeof username === "string" && username.match(/^[a-z0-9]+$/i);
}

export const checkPasswordValidity = password => {
  return password !== undefined && password !== null && typeof password === "string" && password.match(/^[a-zA-Z0-9]+$/i);
}

export const timeoutFetch = (millisecond, promise) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('İstek zaman aşımına uğradı'))
    }, millisecond)

    promise
      .then(value => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch(reason => {
        clearTimeout(timer)
        reject(reason)
      })
  })
}

export const isTableNameValid = (name) => {
  return name && typeof name === 'string' && name.length > 0;
}