import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getTables, getActiveTableName, getUnsavedTable, getTableState, getToken } from './redux/selectors';
import { setActiveTableName, clearActiveTableName, updateTableState, resetTableState, updateJokerList, updateTripleStarIndex, updateAvailableChars, updateKey, updateTables, openUnsavedTable, updateUnsavedTable, clearUnsavedTable, updateSingleTable, prioritizeSingleTable, removeTable } from './redux/actions';
import { saveTableToDB, deleteTableFromDB } from './commonDBOperations';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { isTableNameValid } from './utilities';

const TableTabs = (props) => {
    const [toBeSavedName, setToBeSavedName] = useState("");
    const savedTableNames = props.tables.map(e => e.tableName);

    const UNSAVED_TABLE_NAME = "Kaydedilmemiş";
    const ADD_UNSAVED_TABLE_NAME = '+';

    const getSavedTableVisibleName = (name) => {
        const idx = props.tables.findIndex(e => e.tableName === name);
        const changedName = <span>{name} *</span>;
        const unchangedName = <span>{name}</span>;
        if (-1 === idx) {
            return unchangedName;
        }

        if (undefined === props.tables[idx].currentTable) {
            return unchangedName;
        }

        const tableContainer = props.tables[idx];

        const arraysEqual = (a, b) => {
            if (a === b) {
                return true;
            }
            if (a == null || b == null) {
                return false;
            }
            if (a == undefined || b == undefined) {
                return false;
            }
            if (a.length !== b.length) {
                return false;
            }

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }

        if (tableContainer.table.tableState !== tableContainer.currentTable.tableState ||
            !arraysEqual(tableContainer.table.jokerList, tableContainer.currentTable.jokerList) ||
            !arraysEqual(tableContainer.table.chars, tableContainer.currentTable.chars) ||
            !arraysEqual(tableContainer.table.tripleStarIndex, tableContainer.currentTable.tripleStarIndex)
        ) {
            return changedName;
        }
        return unchangedName;
    }

    const getUnsavedTableVisibleName = () => {
        const style = { fontStyle: "italic" };
        const changedItalicName = <span style={style}>{UNSAVED_TABLE_NAME} *</span>;
        return changedItalicName;
    }

    const unsavedTableClicked = () => {
        props.clearActiveTableName();
        loadUnsavedTable();
    }

    const unsavedTable = {
        name: UNSAVED_TABLE_NAME,
        onClick: unsavedTableClicked,
        selected: undefined == props.activeTableName,
        visibleName: getUnsavedTableVisibleName()
    }

    const addUnsavedTableClicked = () => {
        props.openUnsavedTable();
        props.clearActiveTableName();
        props.updateUnsavedTable(getCurrentTable());
    }

    const addUnsavedTable = {
        name: ADD_UNSAVED_TABLE_NAME,
        onClick: addUnsavedTableClicked,
        selected: false,
        visibleName: (<span>{ADD_UNSAVED_TABLE_NAME}</span>)
    }

    const savedTableClicked = (name) => {
        if (savedTableNames.includes(name)) {
            props.setActiveTableName(name);
            loadTable(name);
        } else {
            console.log(`Called savedTableClicked with ${name} but could not find it`);
        }
    }

    const savedTableList = props.tables.map(e => ({
        name: e.tableName,
        onClick: () => savedTableClicked(e.tableName),
        selected: e.tableName === props.activeTableName,
        visibleName: getSavedTableVisibleName(e.tableName)
    }));

    let tables = [...savedTableList];

    if (props.unsavedTableExists) {
        tables.unshift(unsavedTable);
    } else {
        tables.unshift(addUnsavedTable);
    }

    const loadTable = (tableName) => {
        const tableArr = props.tables.filter(e => e.tableName === tableName);
        if (1 === tableArr.length) {
            let tableContainer = tableArr[0].table;
            if (undefined !== tableArr[0].currentTable) {
                tableContainer = tableArr[0].currentTable;
            }
            props.updateAvailableChars(tableContainer.chars);
            props.updateJokerList(tableContainer.jokerList);
            props.updateTableState(tableContainer.tableState);
            props.updateTripleStarIndex(tableContainer.tripleStarIndex);
            props.updateKey(new Date().getTime());
        } else {
            console.log(`Cannot load table: ${tableName} since it is not present in state.`)
        }
    }

    const loadUnsavedTable = () => {
        props.updateAvailableChars(props.unsavedTable.chars);
        props.updateJokerList(props.unsavedTable.jokerList);
        props.updateTableState(props.unsavedTable.tableState);
        props.updateTripleStarIndex(props.unsavedTable.tripleStarIndex);
        props.updateKey(new Date().getTime());
    }

    const getTableVisibleName = (name) => {
        const idx = props.tables.findIndex(e => e.tableName === name);
        const style = name === UNSAVED_TABLE_NAME ? { fontStyle: "italic" } : {};
        const changedName = <span>{name} *</span>;
        const changedItalicName = <span style={style}>{name} *</span>;
        const unchangedName = <span>{name}</span>;
        if (-1 === idx) {
            if (name === UNSAVED_TABLE_NAME) {
                return changedItalicName;
            }
            return unchangedName;
        }

        if (undefined === props.tables[idx].currentTable) {
            return unchangedName;
        }

        const tableContainer = props.tables[idx];

        const arraysEqual = (a, b) => {
            if (a === b) {
                return true;
            }
            if (a == null || b == null) {
                return false;
            }
            if (a == undefined || b == undefined) {
                return false;
            }
            if (a.length !== b.length) {
                return false;
            }

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }

        if (tableContainer.table.tableState !== tableContainer.currentTable.tableState ||
            !arraysEqual(tableContainer.table.jokerList, tableContainer.currentTable.jokerList) ||
            !arraysEqual(tableContainer.table.chars, tableContainer.currentTable.chars) ||
            !arraysEqual(tableContainer.table.tripleStarIndex, tableContainer.currentTable.tripleStarIndex)
        ) {
            return changedName;
        }
        return unchangedName;
    }

    const saveButtonClicked = async () => {
        if (undefined !== props.activeTableName) {
            try {
                const result = await saveTableToDB(props.activeTableName, getCurrentTable(), props.token);
                if (result) {
                    NotificationManager.success('', 'Tahta başarıyla kaydedildi.', 2000);
                    const updateTablePayload = {
                        table: getCurrentTable(),
                        currentTable: getCurrentTable(),
                        tableName: props.activeTableName
                    };
                    props.updateSingleTable(updateTablePayload);
                    props.prioritizeSingleTable(props.activeTableName);
                } else {
                    NotificationManager.warning('', 'Tahta kaydedilemedi', 2000);
                }
            } catch (err) {
                NotificationManager.warning('', 'Tahta kaydedilemedi', 2000);
            }
        } else {
            if (!isTableNameValid(toBeSavedName)) {
                NotificationManager.warning('', `Tahta ismi uygun değil`, 2000);
                return;
            }
            try {
                const result = await saveTableToDB(toBeSavedName, getCurrentTable(), props.token);
                if (result) {
                    NotificationManager.success('', 'Tahta başarıyla kaydedildi.', 2000);
                    const updateTablePayload = {
                        table: getCurrentTable(),
                        currentTable: getCurrentTable(),
                        tableName: toBeSavedName
                    };
                    props.updateSingleTable(updateTablePayload);
                    props.setActiveTableName(toBeSavedName);
                    props.clearUnsavedTable();
                    setToBeSavedName("");
                } else {
                    NotificationManager.warning('', 'Tahta kaydedilemedi', 2000);
                }
            } catch (err) {
                NotificationManager.warning('', 'Tahta kaydedilemedi', 2000);
            }
        }
    }

    const deleteButtonClicked = async () => {
        if (undefined !== props.activeTableName) {
            try {
                const result = await deleteTableFromDB(props.activeTableName, props.token);
                if (result) {
                    NotificationManager.success('', 'Tahta başarıyla silindi.', 2000);
                    props.removeTable(props.activeTableName);
                    props.clearActiveTableName();
                    if (!props.unsavedTableExists) {
                        props.resetTableState();
                        props.openUnsavedTable();
                    } else {
                        loadUnsavedTable();
                    }
                } else {
                    NotificationManager.warning('', 'Tahta silinemedi', 2000);
                }
            } catch (err) {
                NotificationManager.warning('', 'Tahta silinemedi', 2000);
            }
        } else {
            console.log(`Cannot delete without activeTableName which is ${props.activeTableName}`);
        }
    }

    const getCurrentTable = () => {
        return {
            chars: props.chars,
            jokerList: props.jokerList,
            tableState: props.tableState,
            tripleStarIndex: props.tripleStarIndex
        };
    }

    useEffect(() => {
        const resultantTable = getCurrentTable();
        if (props.activeTableName !== undefined) {
            const idx = props.tables.findIndex((e) => e.tableName === props.activeTableName);
            if (-1 !== idx) {
                const updateTablePayload = {
                    currentTable: resultantTable,
                    tableName: props.activeTableName
                };
                props.updateSingleTable(updateTablePayload);
            }
        } else {
            props.updateUnsavedTable(resultantTable);
        }
    }, [props.tableState, props.chars, props.jokerList, props.tripleStarIndex]);

    const listElems = tables.map((e, idx) => {
        return (
            <li className={e.selected ? "selected" : ""} key={idx} onClick={e.onClick}>
                <div>
                    {e.visibleName}
                </div>
            </li>
        );
    });

    const DeleteSection = () => {
        if (undefined !== props.activeTableName) {
            return (
                <button
                    type="button"
                    className="btn btn-danger quickDeleteButton quickSaveHeight leftRightMargin"
                    onClick={deleteButtonClicked}
                >
                    SİL
            </button>
            )
        } else {
            return null;
        }
    };

    const saveSection = (
        <div className="quickSaveSection">
            <button type="button" className="btn btn-primary quickSaveButton quickSaveHeight leftRightMargin" onClick={saveButtonClicked}>KAYDET</button>
            <DeleteSection />
            <input
                className="quickSaveHeight leftRightMargin quickSaveInput"
                value={toBeSavedName}
                onChange={(e) => setToBeSavedName(e.target.value)}
                style={{ visibility: undefined === props.activeTableName && props.unsavedTableExists ? "" : "hidden" }}
            />
        </div>
    );

    return props.token !== "" && props.token ?
        (
            <div>
                <div id="tabContainer" className="scrollGradient">
                    <ul id="tabDeneme">
                        {listElems}
                    </ul>
                </div>
                {saveSection}
            </div>
        )
        :
        null;
}

const mapStateToProps = (store) => {
    return {
        ...getTables(store),
        ...getActiveTableName(store),
        ...getUnsavedTable(store),
        ...getTableState(store),
        ...getToken(store)
    }
}

const actions = {
    setActiveTableName,
    clearActiveTableName,
    resetTableState,
    updateTableState,
    updateJokerList,
    updateTripleStarIndex,
    updateAvailableChars,
    updateKey,
    updateTables,
    openUnsavedTable,
    updateUnsavedTable,
    clearUnsavedTable,
    updateSingleTable,
    prioritizeSingleTable,
    removeTable
}

export default connect(mapStateToProps,
    actions)
    (TableTabs);