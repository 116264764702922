import { combineReducers } from "redux";
import tableStateReducer from './tableStateReducer';
import availableCharReducer from './availableCharReducer';
import tokenReducer from './tokenReducer'
import userReducer from './userReducer';
import keyReducer from './keyReducer';
import tablesReducer from './tablesReducer';
import activeTableNameReducer from './activeTableNameReducer';
import unsavedTableReducer from './unsavedTableReducer';

export default combineReducers({ 
    tableStateReducer, 
    availableCharReducer, 
    tokenReducer, 
    userReducer, 
    keyReducer, 
    tablesReducer, 
    activeTableNameReducer,
    unsavedTableReducer
 });
