import React from 'react';

class InputBoxContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
        this.infoButtonClicked = this.infoButtonClicked.bind(this);
        this.infoBoxContainerClicked = this.infoBoxContainerClicked.bind(this);
    }

    infoButtonClicked() {
        this.setState({
            visible: true
        });
    }

    infoBoxContainerClicked() {
        this.setState({
            visible: false
        });
    }

    render() {
        const className = this.state.visible ? "visible" : "";
        return (
            <div id="infoBoxWrapper">
                <button type="button" className="btn btn-primary" onClick={this.infoButtonClicked}>?</button>
                <div id="infoBoxContainer" className={className} onClick={this.infoBoxContainerClicked}>
                    <div id="infoBox" onClick={(e) => { e.stopPropagation(); }}>
                        <h5>Nasıl Kullanılır?</h5>
                        <ul>
                            <li><b>Taşlarınız</b> yazan kutucuğa elinizdeki taşları yazabilirsiniz.</li>
                            <li>Joker kullanmak için <b>X</b> harfini kullanabilirsiniz.</li>
                            <li>Ardından <b>ÇÖZ</b> butonuna basarsanız çözme işlemi başlayacaktır.</li>
                            <li>Bulunan çözümler puanlarına veya kullanılan harf sayısına göre sıralacaktır.</li>
                            <li>Çözümlerin üzerine tıklandığında çözümün tahta üzerindeki konumunu görebilirsiniz.</li>
                            <li>Eğer çözümü beğendiyseniz <b>ÇÖZÜMÜ UYGULA</b> butonuna basarak çözümü tahtaya kalıcı olarak uygulayabilirsiniz.</li>
                            <li>Tahtada konumunu gördüğünüz ancak uygulanmamış olan çözümleri ise <b>ÇÖZÜM TEMİZLE</b> butonu ile kaldırabilirsiniz.</li>
                            <li><b>TAHTAYI TEMİZLE</b> butonu ile tahtadaki her şeyi silebilirsiniz.</li>
                            <li><b>Girilecek Kelime</b> yazan kutucuğu tahtada bir pozisyona toplu kelime girmek için kullanabilirsiniz.</li>
                            <li>Yazmak istediğiniz kelimeyi yazdıktan sonra <b>SAĞA</b> veya <b>AŞAĞIYA</b> butonlarına basarak kelimenin girilmesini sağlayabilirsiniz.</li>
                            <li>Tahtada üç yıldız işaretinin olduğu yeri belirtmek için boş bir kareye çift tıklayabilirsiniz.</li>
                            <li>Tahtadaki bir karakterin joker olduğunu belirtmek için harfin olduğu kutucuğa çift tıklayabilirsiniz.</li>
                            <li>Hesabınızı oluşturduktan sonra ise oynadığınız tahtayı daha sonra kullanabilmek için kaydedebilirsiniz.</li>
                            <li>Kaydettiğiniz tahtaları ise daha sonra verdiğiniz isimle geri getirebilirsiniz.</li>
                            <li>En son kaydettiğiniz 5 tahtanız ise kolayca geri yüklenebilmesi için <b>Tahtalarım</b> menüsünde yer almaktadır.</li>
                            <li>Görüş ve önerilerinizi twitter/@kelimeyardim veya kelimeyardim@gmail.com adresine bildirebilirsiniz.</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default InputBoxContainer;
